import React,{useEffect,useRef,useState} from 'react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import './starts-rank-style.css';

function StartsRankComponent({dispatchFunction,keyOperatorData,resetStarts}) {
    const startsRef = useRef([]);
    const addStart = (key)=>{
        let valueData=key+1;
        startsRef.current.map((el,index)=>{
            let startElement = el.children[0];
            let addClassOrRemove;
            if(key===0 && index===0 && startElement.classList.contains('start-checked') &&  !startsRef.current[1].children[0].classList.contains('start-checked')){
                startElement.classList.remove('start-checked');
                valueData=0;
            }else{
                if(index<=key){
                    if(startElement.classList.contains('start-checked')) addClassOrRemove = false
                    addClassOrRemove ? startElement.classList.remove('start-checked') : startElement.classList.add('start-checked') 
                }
                else{
                    !addClassOrRemove ? startElement.classList.remove('start-checked') : startElement.classList.add('start-checked') 
                } 
            }
        })
        return dispatchFunction(keyOperatorData,valueData)
    }

    useEffect(() => {
        resetStarts(prevRefs => [...prevRefs, startsRef]);
    }, [resetStarts]);
    return (
        <>
            {
                [...Array(5)].map((i,index)=>{
                    return  <span ref={el =>startsRef.current[index] = el} key={index}>
                                <FontAwesomeIcon key={index}
                                className='start' 
                                onClick={()=>addStart(index)} 
                                icon={faStar}>
                                </FontAwesomeIcon>
                            </span>  
                })
           }
        </>        
    );
}

export default StartsRankComponent;
