import React,{useRef,useEffect,useState} from 'react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRightFromBracket,faUserCircle,faHouseCircleCheck, faScrewdriverWrench} from '@fortawesome/free-solid-svg-icons';
import './menu-style.css';
import { Link } from 'react-router-dom';
import WhoIamService from '../../../services/WhoIam';
import ErrorsFactory from '../../../helpers/errorsFactory';


function MenuComponent() {
    const itemsRef = useRef([]);
    const [checkIfIamAdmin,setCheckIfIamAdmin] = useState(false);
    useEffect(() => {
        getWhoIamAdmin();
    }, []);
    const menu = [
        {
            name:'Servicios',
            icon:faHouseCircleCheck,
            link:'services',
            show:true,
        },
        {
            name:'Mi Cuenta',
            icon:faUserCircle,
            link:'my-account',
            show:true,
        },
        {
            name:'Administracion',
            icon:faScrewdriverWrench,
            link:'admin-panel',
            show:checkIfIamAdmin
        },
        {
            name:'Cerrar Sesión',
            icon:faRightFromBracket,
            link:'cerrar-sesion',
            show:true
        }
    ]
    const addClass = (key)=>{
        itemsRef.current.map((item,i)=>{
            if(i===key) return item.classList.add('active');
            else return item.classList.remove('active');
        })
        return;
    }
    const getWhoIamAdmin = async ()=>{
        const whoIam = await WhoIamService.whoIamAdmin();
        if(ErrorsFactory.admin[whoIam]) setCheckIfIamAdmin(true);
    }
    return (
        <nav className="sidebar-navigation">
            <ul>
                {
                    menu.map((item,i) =>{
                        if(item.show){
                            return  <Link key={i} to={item.link}>
                                        <li ref={el =>{
                                            if(el!==null){
                                                itemsRef.current[i] = el
                                                if(item.link===window.location.pathname.split('/')[1]) itemsRef.current[i].classList.add('active');
                                            }
                                        }} onClick={()=> addClass(i)}>
                                            <FontAwesomeIcon className='icon-menu' icon={item.icon}/>
                                            <span className="tooltip">{item.name}</span>
                                        </li>
                                    </Link>
                        }
                    })
                }
            </ul>
        </nav>
    );
}

export default MenuComponent;