import ApiService from "../helpers/api";

const getAllUsers = () => {
    return ApiService.get(`users/all_users`);
}

const updateAdminUser = (data) => {
    return ApiService.update(`users/update_user_by_admin/${data.id}`,data);
}

const AllUsersService = {
    getAllUsers,
    updateAdminUser
}

export default AllUsersService;