import React,{useRef,useEffect,useState} from 'react'; 
import ButtonsComponent from '../buttons/buttonsComponent';
import './confirm-style.css';

function ConfirmComponent(props) {
    const [showConfirmWindow,setShowConfirmWindow] = useState(false);
    const confirmRef = useRef([]);

    useEffect(() => {
        setShowConfirmWindow(props.showConfirm)
    }, [props.showConfirm]);

    const dispatchConfirm = (type) => {
        confirmRef.current.classList.add("slice-top");
        setTimeout(()=>{
            confirmRef.current.classList.remove("slice-top");
            if(type) props.dispatchConfirmFunction();
            props.setShowConfirmWindow(false)
            return setShowConfirmWindow(false)
        },1000)
    }

    return (
        <>
            {
            showConfirmWindow ? <div ref={confirmRef} className={`general-confirm ${props.type}`}>
            <p>{props.message}</p>
            <ButtonsComponent 
                type='button' 
                classButton='yes-confirmation'
                dispatchClick={()=>dispatchConfirm(true)}
                nameButton='Si'
                aling='center'
                adinCss={{'margin':'10px 5px'}}
            >
            </ButtonsComponent>
            <ButtonsComponent 
                type='button' 
                classButton='no-confirmation'
                dispatchClick={()=>dispatchConfirm(false)}
                nameButton='No'
                aling='center'
                adinCss={{'margin':'10px 5px'}}
            >
            </ButtonsComponent>
        </div> : ''
        }
        </>
    );
}

export default ConfirmComponent;
