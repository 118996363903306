import React,{useEffect,useState} from 'react'; 
import SendEmailActiveAcountModalComponent from '../generalComponents/modals/childsModalsComponent/sendEmailActiveAcount/sendEmailAgainModal';
import ActiveUserForgotPasswordService from '../../services/ActiveUserForgotPasswordService';
import './active-account-style.css';
import {
    useParams,
    useNavigate
} from "react-router-dom";
function ActiveAccountComponent() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [showComponent,setShowComponent] = useState(false);
    const initSesion = ()=>{
        return navigate("/login");
    }
    useEffect(() => {
        activeUser();
    }, []);

    const successLink = () =>{
        return (
            <div className='child'>
                <h1>Bienvenido a ME&YOU</h1>
                <p>
                    Su cuenta ha sido activada, esperamos que disfrute de todo el contenido y pueda aportar tambien al mismo.
                </p>
                <span className='secondary span-init-session' onClick={initSesion}>
                    Iniciar Sesion
                </span>
            </div>
        )
    }

    const errorLink = () =>{
        return (
            <div className='child-error-link'>
                <div>
                    <h1>Su enlace ha caducado</h1>
                    <p>
                        El enlace que le hemos enviado ha caducado.Pida un nuevo email de activación
                    </p>
                <SendEmailActiveAcountModalComponent/>
                </div>
            </div>
        )
    }

    const activeUser = async()=>{
        const active = await ActiveUserForgotPasswordService.activeUser(token);
        if(active.data!==1) return setShowComponent(errorLink())
        return setShowComponent(successLink());
    }
    return (
        <div className="parent-active-account">
           {showComponent}
        </div>
    );
}
  
  export default ActiveAccountComponent;
  