import React, {useRef} from 'react'; 
import MenusFactory from './menuFactory';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './horizontal-menu.css';

function HorizontalMenuComponent(props) {
    const itemsRef = useRef([]);
    const menuSelected = MenusFactory[props.menuSelected];
    const addClass = (key)=>{
        itemsRef.current.map((item,i)=>{
            if(i===key) item.classList.add('active');
            else item.classList.remove('active');
        })
        return;
    }
  return (
    <nav className="menu-horizontal">
        <ul>
            {
                menuSelected.map((item,i) =>{
                    
                    return  <Link key={i} to={item.link}>
                                <li ref={el =>{
                                    itemsRef.current[i] = el
                                    //if(item.link===window.location.pathname.split('/')[2]) itemsRef.current[i].classList.add('active');
                                    }} onClick={()=> addClass(i)}>
                                    <FontAwesomeIcon className='icon-menu' icon={item.icon}/>
                                    <span className="tooltip">{item.name}</span>
                                </li>
                            </Link>
                })
            }
        </ul>
    </nav>
  );
}

export default HorizontalMenuComponent;
