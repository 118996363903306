import ApiService from "../helpers/api";

const login = (data) => {
    return ApiService.post(`login`,data);
}
const logout = () => {
    return ApiService.get(`logout`);
}
const register = (data) => {
    return ApiService.post(`register`,data);
}

const checkUser = () => {
    return ApiService.get(`who_i_am`);
}

const LoginService = {
    login,
    logout,
    register,
    checkUser
}

export default LoginService;