import {faUserCircle,faBuilding,faPlus,faArrowUp } from "@fortawesome/free-solid-svg-icons";

const adminMenu = [
    {
        name:'Usuarios',
        icon:faUserCircle,
        link:'all-users',
    },
    {
        name:'Gremios',
        icon:faBuilding,
        link:'gremios',
    }
]

const servicesMenu = [
    {
        name:'Todos los servicios',
        icon:faArrowUp,
        link:'all-services',
    },
    {
        name:'Crear Servicio',
        icon:faPlus,
        link:'create-service',
    }
]

const MenusFactory = {
    adminMenu,
    servicesMenu
}

export default MenusFactory;