
const newSesion = ({id,name,surname,date_of_birth,type_user,token}) => {
    localStorage.setItem("idUser",id);
    localStorage.setItem("userName",`${name} ${surname}`);
    localStorage.setItem("dateBirth",date_of_birth);
    //localStorage.setItem("typeUser",type_user);
    return true;
}

const destroySesion = () => {
    return localStorage.clear();
}

const getUserId = () => {
    return localStorage.getItem('idUser');
}

const getDataUser = () =>{
    return {
        idUser:getUserId(),
        userName:localStorage.getItem('userName'),
        dateBirth:localStorage.getItem('dateBirth'),
    }
}

const Auth = {
    newSesion,
    destroySesion,
    getUserId,
    getDataUser
}

export default Auth;