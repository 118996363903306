import React,{useEffect,useState,useRef,useContext} from 'react'; 
import ButtonsComponent from '../../../../generalComponents/buttons/buttonsComponent';
import AllUsersService from '../../../../../services/UsersService';
import './all-users-style.css';
import { rootContext } from '../../../../../App';

function DataUserComponent({dataUser}) {
    const [usersData,setUserData] = useState({id:'',name:'',surname:'',date_of_birth:'',email:'',range:'',activated:'',block:'',terms_accepted:'',deleted:'',register:'',updated:'',chances:''});
    const {handleShowFlag} = useContext(rootContext);
    useEffect(() => {
        setUserData(dataUser)
    }, []);

    const handleChange = (e) =>{
        const {name,value} = e.target;
        return setUserData({...usersData,[name]:value});
    }

    const updateData = async (e) =>{
        e.preventDefault()
        let updateUser = await AllUsersService.updateAdminUser(usersData);
        if(updateUser==='error') handleShowFlag('error','Ha habido un error','topToDown');
        else handleShowFlag('success','Operador Actualizado Corractamente','topToDown'); 
    }

    return (
        <>
            <form onSubmit={updateData}>         
                <input type="hidden" name="id" placeholder="id" value={usersData.id} onChange={handleChange}/>
                <label>Nombre</label>
                <input type="text" name="name" placeholder="Name" value={usersData.name} onChange={handleChange}/>                               
                <label>Apellidos</label>
                <input type="text" name="surname" placeholder="Apellidos" value={usersData.surname} onChange={handleChange}/>                               
                <label>Fecha de nacimiento</label>
                <input type="text" name="date_of_birth" placeholder="Fecha de nacimiento" value={usersData.date_of_birth} onChange={handleChange}/>                               
                <label>Email</label>
                <input type="email" name="email" placeholder="Email" value={usersData.email} onChange={handleChange}/>
                <label>Rango</label>
                <select type="text" name="range" placeholder="Rango" value={usersData.range} onChange={handleChange}>
                    <option value='admin'>Administrador</option>
                    <option value='normal_user'>Usuario</option>
                </select>
                <label>Activo</label>
                <select type="number" name="activated" placeholder="Activo" value={usersData.activated} onChange={handleChange}>
                    <option value='0'>No Activo</option>
                    <option value='1'>Activo</option>
                </select>
                <label>Bloqueado</label>
                <select type="number" name="block" placeholder="Bloquedao" value={usersData.block} onChange={handleChange}>
                    <option value='0'>No Bloqueado</option>
                    <option value='1'>Bloqueado</option>
                </select>
                <label>Terminos aceptados</label>
                <select type="number" name="terms_accepted" placeholder="Terminos aceptados" value={usersData.terms_accepted} onChange={handleChange}>
                    <option value='0'>Terminos No Aceptados</option>
                    <option value='1'>Terminos Aceptados</option>
                </select>
                <label>Borrado</label>
                <select type="number" name="deleted" placeholder="Borrado" value={usersData.deleted} onChange={handleChange}>
                    <option value='0'>Borrado</option>
                    <option value='1'>No Borrado</option>
                </select>
                <label>Fecha de registro</label>
                <input type="text" name="register" placeholder="Registrado" value={usersData.register} onChange={handleChange} readOnly/>
                <label>Fecha de actualizacion</label>
                <input type="text" name="updated" placeholder="Actualizado" value={usersData.updated} onChange={handleChange} readOnly/>
                <label>Oportunidades de inicio de sesion</label>
                <input type="number" min='0' max='10' name="chances" placeholder="Oportunidades" value={usersData.chances} onChange={handleChange}/>
                <ButtonsComponent 
                    type='submit' 
                    classButton='info-button'
                    nameButton='Actualizar'
                    aling='right'
                ></ButtonsComponent>
            </form>
        </>
    );
}

export default DataUserComponent;