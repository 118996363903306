import React from 'react'; 
import SendEmailActiveAcountModalComponent from './childsModalsComponent/sendEmailActiveAcount/sendEmailAgainModal';
import SessionExpiredModalComponent from "./childsModalsComponent/sessionExpiredModalComponent/sessionExpiredModalComponent";

const ModalsChildsFactory = {
    modalExpiredSession:{
        view:()=><SessionExpiredModalComponent/>
    },
    modalSendEmailActiveAcount:{
        view:()=><SendEmailActiveAcountModalComponent/>
    }
}

export default ModalsChildsFactory;