import React, {useState,createContext,lazy,Suspense} from 'react'; 
import LoginComponent from './components/loginComponent/loginComponent';
import RegisterComponent from './components/registerComponent/registerComponent';
import ActiveAccountComponent from './components/activeAccountComponent/activeAccountComponent';
import ParentModalComponent from './components/generalComponents/modals/parentModalComponent';
import jflaFactoryValues from './helpers/jflagsFactoryValues';
import { JFlag } from 'j-flags';
import LoaderPrincipalComponent from './components/generalComponents/loader/loaderPrincipalComponent';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
export const rootContext = createContext();
const LazyMainComponent = lazy(() => import('./components/panelControl/main/mainComponent'));

function App() {
  const [showFalg,setShowFlag] = useState(false);
  const [showLoader,setShowLoader] = useState(true);
  const [propsFlag,setPropsFlag] = useState({text:'',sound:'',typeF:'',animation:'',cutomStyles:''});
  const [modalSelected,setModalSelected] = useState('');
  const [showModalSelected,setShowModalSelected] = useState(false);
  
  const handleShowFlag = (typeFlag,text,animation='fadeIn')=>{
    setPropsFlag(jflaFactoryValues[typeFlag].values(text,animation))
    setShowFlag(true)
    setTimeout(()=>{
      setShowFlag(false)
    },4000)
    return;
  }

  setTimeout(()=>{
    setShowLoader(false)
  },2500)
  
  return (
    <>
    {
      showLoader ? <div><LoaderPrincipalComponent/></div> : <rootContext.Provider value={{handleShowFlag,setModalSelected,setShowModalSelected}}>
        {
          showFalg ? 
          <JFlag 
              text={propsFlag.text}
              sound={propsFlag.sound}
              typeF={propsFlag.typeF}
              animation={propsFlag.animation}
              customStyles={propsFlag.customStyles}
          /> : ''
        }
        {
          showModalSelected ? <ParentModalComponent modalSelected={modalSelected} showModal={setShowModalSelected} /> : ''
        }
        <Router>
          <Suspense fallback={<LoaderPrincipalComponent />}>
            <Routes>
              <Route path='/active-account/:token' element={<ActiveAccountComponent/>}/>
              <Route path='/register-acount' element={<RegisterComponent/>}/>
              <Route path='/login' element={<LoginComponent/>}/>
              <Route path='/*' element={<LazyMainComponent/>}>
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </rootContext.Provider>
    }
    </>

  );
}

export default App;
