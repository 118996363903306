import ApiService from "../helpers/api";

const checkUser = () => {
    return ApiService.get(`who_i_am`);
}

const whoIamAdmin = () =>{
    return ApiService.get(`who_admin`);
}

const WhoIamService = {
    checkUser,
    whoIamAdmin
}

export default WhoIamService;