import React,{useEffect,useState,useRef,useContext} from 'react'; 
import ButtonsComponent from '../../../../generalComponents/buttons/buttonsComponent';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import './guilds-style.css';
import GuildsService from '../../../../../services/GuildsService';
import OperatorsTypeComponent from './operatorsTypeComponent';
import ConfirmComponent from '../../../../generalComponents/confirm/comfirmComponent';
import { rootContext } from '../../../../../App';


function GuildsComponent() {
    const [createOperator,setCreateOperator] = useState({name:'',need_calification:1});
    const [operatorsData,setOperatorsData] = useState('');
    const [showConfirmWindow,setShowConfirmWindow] = useState(false);
    const [getIndexForDelete,setGetIndexForDelete] = useState('');
    const {handleShowFlag} = useContext(rootContext);
    const sectionDataRef = useRef([]);
    useEffect(() => {
        getAllGuilds();
    }, []);
    const getAllGuilds = async ()=>{
        const allOperatosData = await GuildsService.getAllOperatorsName();
        setOperatorsData(allOperatosData.data);
    }

    const handleChange = (e) =>{
        const {name,value} = e.target;
        return setCreateOperator({...createOperator,[name]:value});
    }

    const createOperatorName = async (e) =>{
        e.preventDefault()
        let create = await GuildsService.createOperatorName(createOperator);
        if(create==='error') handleShowFlag('error','Ha habido un error','topToDown');
        else handleShowFlag('success','Operador Creado Corractamente','topToDown');
        setCreateOperator({name:'',need_calification:1});
        getAllGuilds();
    }

    const seeUserData = (index)=>{
        sectionDataRef.current[index].style.display='block'
    }

    const closeUserdata = (index)=>{
        sectionDataRef.current[index].classList.add("fade-out");
        setTimeout(()=>{
            sectionDataRef.current[index].style.display='none'
            sectionDataRef.current[index].classList.remove("fade-out");
        },300)
    }

    const showConfirmWindowDeleteOperator = (index)=>{
        setShowConfirmWindow(true);
        setGetIndexForDelete(index);
    }

    const deleteOperator = async ()=>{
        await GuildsService.deleteOperator(operatorsData[getIndexForDelete].id);
        handleShowFlag('success','Operador Borrado Corractamente','topToDown');
        getAllGuilds();
    }

    return (
        <>  
            <ConfirmComponent 
                message='¿Estas seguro de que quieres borrar este operador? Se borraran todos los tipos tambien' 
                type='warning' 
                showConfirm={showConfirmWindow} 
                setShowConfirmWindow={setShowConfirmWindow} 
                dispatchConfirmFunction={deleteOperator}>
            </ConfirmComponent>
            <section className='guilds-section content-data'>
                <form onSubmit={createOperatorName}> 
                    <h1>Crear nuevo Gremio</h1> 
                    <label>Nombre Gremio</label>
                    <input type="text" name="name" placeholder="Name" value={createOperator.name} onChange={handleChange}/>                               
                    <label>Necesita calificacion</label>
                    <select type="text" name="need_calification" placeholder="Necesita calificacion" value={createOperator.need_calification} onChange={handleChange}>
                        <option value='1'>Si</option>
                        <option value='0'>No</option>
                    </select>
                    <ButtonsComponent 
                        type='submit' 
                        classButton='info-button'
                        nameButton='Crear Gremio'
                        aling='right'
                    >
                    </ButtonsComponent>
                </form>
            </section>
            <hr/>
            {
                operatorsData.length>0 ?
                    <section className='all-users-section content-data'>
                        {
                            operatorsData.map((data,index)=>{
                                return <div key={index} className='each-operator-div'>
                                            {data.name}
                                            <ButtonsComponent 
                                                type='button' 
                                                classButton='delete-button'
                                                dispatchClick={()=>showConfirmWindowDeleteOperator(index)}
                                                nameButton='Borrar Operador'
                                                aling='right'
                                                adinCss={{'marginLeft':'10px'}}
                                            ></ButtonsComponent>
                                            <ButtonsComponent 
                                                type='button' 
                                                classButton='info-button'
                                                dispatchClick={()=>seeUserData(index)}
                                                nameButton='Ver informacion'
                                                aling='right'
                                            >
                                            </ButtonsComponent>
                                            <section ref={el => sectionDataRef.current[index] = el} className='guilds-section-data'>
                                                <div className='div-close'>
                                                    <FontAwesomeIcon className='close-user-data' icon={faCircleXmark} onClick={()=>{closeUserdata(index)}}/>
                                                </div>
                                                <OperatorsTypeComponent  dataOperator={data} getAllGuilds={getAllGuilds} handleShowFlag={handleShowFlag}>
                                                </OperatorsTypeComponent>
                                            </section> 
                                        </div>
                            })
                        }
                    </section>
                : ''
            }
        </>
    );
}

export default GuildsComponent;