import React,{useEffect,useState,useRef,useContext} from 'react'; 
import ButtonsComponent from '../../../../generalComponents/buttons/buttonsComponent';
import './create-service-style.css';
import GuildsService from '../../../../../services/GuildsService';
import OperatorsService from '../../../../../services/OperatorsService';
import ConfirmComponent from '../../../../generalComponents/confirm/comfirmComponent';
import StartsRankComponent from '../../../../generalComponents/startsRanking/startsRankingComponent';
import { rootContext } from '../../../../../App';
import { ContenContex } from '../../contentComponent';

function CreateServiceComponent() {
    const [errors, setErrors] = useState({});
    const [operatorsData,setOperatorsData] = useState({name_operator:'',telephon:'',id_operator_name:'',id_operator_type:'',email:'',cif:'',nif:'',speed_response:0,speed_check_job:0,speed_send_budget:0,price_compared_agreed:0,job_satisfaction:0,comments:''});
    const [operatorNameData,setOperatorNameData] = useState([]); 
    const [showConfirmWindow,setShowConfirmWindow] = useState(false);
    const operatorTypeRef = useRef([]);
    const labelRef = useRef();
    const [resetStarts,setResetStarts] = useState([]); 
    const {handleShowFlag} = useContext(rootContext);
    const {setShowLoader} = useContext(ContenContex);

    useEffect(() => {
        setShowLoader(true)
        getAllGuilds();
        setShowLoader(false)
    }, []);
    const getAllGuilds = async ()=>{
        const allOperatosData = await GuildsService.getAllOperatorsName();
        setOperatorNameData(allOperatosData.data);
    }

    const handleChange = (e) =>{
        showTypeOperatorSelect(e);
        const {name,value} = e.target;
        setOperatorsData({...operatorsData,[name]:value});
    }

    const changeStateViaStarts = (name,value)=>{
        setOperatorsData({...operatorsData,[name]:value});
    }

    const showTypeOperatorSelect = (el) =>{
        if(el.target.name!=='id_operator_name') return
        let selectedIndex = el.target.selectedIndex-1;
        if(selectedIndex<0) return
        operatorTypeRef.current.map((el,i)=>{
            if(operatorNameData[selectedIndex].operatorTypes.length<=0){
                el.classList.remove('show-type-operator')
                labelRef.current.style.display='none'
            }else{
                if(selectedIndex===i) el.classList.add('show-type-operator')
                else el.classList.remove('show-type-operator')
                labelRef.current.style.display='block'
            }
        })
    }

    const validate = () => {
        const errors = {};
        if (!operatorsData.name_operator || operatorsData.name_operator==='') errors.name_operator = 'El nombre del operador es obligatorio';
        if (!operatorsData.telephon) errors.telephon = 'El teléfono es obligatorio';
        else if (!/^\d{3}-\d{2}-\d{2}-\d{2}$/.test(operatorsData.telephon)) errors.telephon = 'El formato del teléfono es inválido';
        if (!operatorsData.id_operator_name) errors.id_operator_name = 'El gremio es obligatorio';
        if(labelRef.current.style.display==='block' && (!operatorsData.id_operator_type || operatorsData.id_operator_type==='')) errors.id_operator_type = 'El Tipo de gremio es obligatorio';
        
        return errors;
    }

    const createOperator = async (e) =>{
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        let create = await OperatorsService.createOperator(operatorsData);
        if(create==='error') return handleShowFlag('error','Ha Habido Un Problema En La Creación','topToDown');
        else handleShowFlag('success','Creación Correcta','topToDown');
        resetAll();
        
    }

    const resetAll = ()=>{
        resetStarts.map(el=>{
            el.current.map(star=>{
                star.children[0].classList.remove('start-checked');
            })
        })
        setOperatorsData({
            name_operator:'',
            telephon:'',
            id_operator_name:'',
            id_operator_type:'',
            speed_response:0,
            speed_check_job:0,
            speed_send_budget:0,
            price_compared_agreed:0,
            job_satisfaction:0,
            comments:'',
            email:'',
            cif:'',
            nif:''
        })
        setErrors({});

    }

    // funcion para mostrar el select primero de los tipos de operadores
    return (
        <>  
            <ConfirmComponent message='¿Estas seguro de que quieres borrar este operador? Se borraran todos los tipos tambien' type='warning' showConfirm={showConfirmWindow} setShowConfirmWindow={setShowConfirmWindow}></ConfirmComponent>
            <section key={'guilds'} className='guilds-section content-data'>
                <form onSubmit={createOperator}> 
                    <h1>Crear nuevo Operador</h1> 
                    <label>Nombre Operador <small className='mandatory' title="Campo Obligatorio">*</small></label>
                    <input type="text" name="name_operator" placeholder="Nombre de operador" value={operatorsData.name_operator} onChange={handleChange}/>                               
                    {errors.name_operator && <p className="error-form">{errors.name_operator}</p>}

                    <label>Teléfono <small>Formato: 699-99-99-99 <span className='mandatory' title="Campo Obligatorio">*</span></small></label>
                    <input type="text" name="telephon" placeholder="Telefono" value={operatorsData.telephon} pattern="[0-9]{3}-[0-9]{2}-[0-9]{2}-[0-9]{2}" onChange={handleChange}/>
                    {errors.telephon && <p className="error-form">{errors.telephon}</p>}

                    <label>Email <small className='no-mandatory'>?</small></label>
                    <input type="email" name="email" placeholder="Email" value={operatorsData.email} onChange={handleChange}/>
                    
                    <label>CIF <small className='no-mandatory'>?</small></label>
                    <input type="text" name="cif" placeholder="CIF" value={operatorsData.cif} onChange={handleChange}/> 
                    
                    <label>NIF <small className='no-mandatory'>?</small></label>
                    <input type="text" name="nif" placeholder="NIF" value={operatorsData.nif} onChange={handleChange}/>                               
                    
                    <label>Gremio <small className='mandatory' title="Campo Obligatorio">*</small></label>
                    <select name='id_operator_name' value={operatorsData.id_operator_name} onChange={handleChange}>
                        <option value={null}>Elige un Gremio</option>
                        {
                            operatorNameData.map((data,index)=>{
                                return <option key={'operatorname'+data.id} value={data.id}>{data.name}</option>
                            })
                        }
                    </select> 
                    {errors.id_operator_name && <p className="error-form">{errors.id_operator_name}</p>}

                    <label ref={labelRef} className='label-guild'>Tipo de Gremio <small>*</small></label>
                        {
                            operatorNameData.map((data,index)=>{ 
                                return <>
                                <select ref={el => operatorTypeRef.current[index] = el} name='id_operator_type' key={index} className={`type-gilds`} onChange={handleChange}>
                                    <option key={data.id} value={null}>Elige un Tipo de Gremio</option>
                                    {
                                        data.operatorTypes.map((type,i)=>{
                                            return <option key={index+i+type.id} value={type.id}>{type.name_type}</option>
                                        })
                                    }
                                </select>
                                </>
                            })
                        }
                    {errors.id_operator_type && <p className="error-form">{errors.id_operator_type}</p>}
                    <div className='assessment'>
                        <div>
                            <label>Velocidad en responder</label>
                            <input type="hidden" name="speed_response" value={operatorsData.speed_response} onChange={handleChange}/>                               
                            <div className='div-starts'>
                                <StartsRankComponent resetStarts={setResetStarts} dispatchFunction={changeStateViaStarts} keyOperatorData='speed_response'></StartsRankComponent>
                            </div>
                        </div>
                        <div>
                            <label>Velocidad en ver el trabajo</label>
                            <input type="hidden" name="speed_check_job" value={operatorsData.speed_check_job} onChange={handleChange}/>                               
                            <div className='div-starts'>
                                <StartsRankComponent resetStarts={setResetStarts} dispatchFunction={changeStateViaStarts} keyOperatorData='speed_check_job'></StartsRankComponent>
                            </div>
                        </div> 
                        <div>
                            <label>Velocidad en enviar presupuesto</label>
                            <input type="hidden" name="speed_send_budget" value={operatorsData.speed_send_budget} onChange={handleChange}/>                               
                            <div className='div-starts'>
                                <StartsRankComponent resetStarts={setResetStarts} dispatchFunction={changeStateViaStarts} keyOperatorData='speed_send_budget'></StartsRankComponent>
                            </div>
                        </div>
                        <div>
                            <label>Precio Comparado acordado</label>
                            <input type="hidden" name="price_compared_agreed" value={operatorsData.price_compared_agreed} onChange={handleChange}/>                               
                            <div className='div-starts'>
                                <StartsRankComponent resetStarts={setResetStarts} dispatchFunction={changeStateViaStarts} keyOperatorData='price_compared_agreed'></StartsRankComponent>
                            </div>
                        </div>
                        <div>
                            <label>Satisfacción del trabajo</label>
                            <input type="hidden" name="job_satisfaction" value={operatorsData.job_satisfaction} onChange={handleChange}/>                               
                            <div className='div-starts'>
                                <StartsRankComponent resetStarts={setResetStarts} dispatchFunction={changeStateViaStarts} keyOperatorData='job_satisfaction'></StartsRankComponent>
                            </div>
                        </div>
                    </div>
                    <label className='comment-box-service'>Comentarios <small className='no-mandatory'>?</small></label>
                    <textarea name="comments" value={operatorsData.comments} placeholder='Añade un comentario' onChange={handleChange}></textarea>
                    <ButtonsComponent 
                        type='submit' 
                        classButton='custom-button'
                        nameButton='Crear Operador'
                        aling='right'
                    >
                    </ButtonsComponent>
                </form>
            </section>
            <hr/>
        </>
    );
}

export default CreateServiceComponent;