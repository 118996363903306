import ApiService from "../helpers/api";

const getAllOperators = () => {
    return ApiService.get(`operators/`);
}
const createOperator = (data) => {
    return ApiService.post(`operators/create`,data);
}

const updateOperator = (data) => {
    return ApiService.update(`operators/update/${data.id}`,data);
}

const deleteOperator = (id) => {
    return ApiService.deleteData(`operators/delete/${id}`);
}

const OperatorsService = {
    getAllOperators,
    createOperator,
    deleteOperator,
    updateOperator
}

export default OperatorsService;