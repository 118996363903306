import React, { useEffect,useRef,useState } from 'react'; 
import ResendEmailService from '../../../../../services/ResendEmail';
function SendEmailActiveAcountModalComponent(props) {
  const emailRef = useRef(null);
  const [emailSended,setEmailSended] = useState(null)

  useEffect(() => {
  }, [props]);
  const sendMail = async (e)=>{
    e.preventDefault();
    const resendEmail = await ResendEmailService.emailForactiveUser({email:emailRef.current.value})
    setEmailSended('Si es correcto su email hemos enviado uno para que active su cuenta, revíselo por favor');
  }
  return (
    <div className='child-in'>
      <h1 className='title-modal'>Enviar email de activación</h1>
      <form className="form-login-expired" onSubmit={sendMail}>
          <input type="email" placeholder="Email" ref={emailRef} required/>
          <button className='primary'>Enviar Email</button>
      </form>
      <p className='successs-message'>{emailSended}</p>
    </div>
  );
}

export default SendEmailActiveAcountModalComponent;
