import React from 'react'; 
import './loader-component-style.css';

function LoaderComponent() {
  return (
    <>
        <div className='container-loader'>
            <span className="loader"></span>    
        </div>
    </>
  );
}

export default LoaderComponent;