import React,{useEffect,useState,useRef,useContext} from 'react'; 
import './all-services-style.css';
import OperatorsService from '../../../../../services/OperatorsService';
import OperatorsCardComponent from './operatorsCardComponent';
import { rootContext } from '../../../../../App';
import { ContenContex } from '../../contentComponent';

function AllServicesComponent() {
    const [errors, setErrors] = useState({});
    const [operatorsData,setOperatorData] = useState([]); 
    const {handleShowFlag} = useContext(rootContext);
    const {setShowLoader} = useContext(ContenContex);

    const getAllServices = async ()=>{
        const allOperatosData = await OperatorsService.getAllOperators();
        setOperatorData(allOperatosData.data);
    }

    useEffect(() => {
        setShowLoader(true)
        getAllServices();
        setShowLoader(false)
    }, []);


    const handleChange = (e) =>{
    }

    const validate = () => {
        const errors = {};
        // if (!operatorsData.name_operator || operatorsData.name_operator==='') errors.name_operator = 'El nombre del operador es obligatorio';
        // if (!operatorsData.telephon) errors.telephon = 'El teléfono es obligatorio';
        // else if (!/^\d{3}-\d{2}-\d{2}-\d{2}$/.test(operatorsData.telephon)) errors.telephon = 'El formato del teléfono es inválido';
        // if (!operatorsData.id_operator_name) errors.id_operator_name = 'El gremio es obligatorio';
        // if(labelRef.current.style.display==='block' && (!operatorsData.id_operator_type || operatorsData.id_operator_type==='')) errors.id_operator_type = 'El Tipo de gremio es obligatorio';
        
        // return errors;
    }

    // funcion para mostrar el select primero de los tipos de operadores
    return (
        <>  
            <div className="operators-list">
                { operatorsData.length>0 ? operatorsData.map((operator,index)=>{
                        return <OperatorsCardComponent key={index} operatorsData={operator}></OperatorsCardComponent>
                    }) : ''
                }
            </div>
        </>
    );
}

export default AllServicesComponent;