import React,{useContext} from 'react'; 
import './all-services-style.css';
import capitalizeFirstLetter from './../../../../../tools/capitalize';
import RatingComponent from './ratingComponent';
import { rootContext } from '../../../../../App';

function OperatorsCardComponent({operatorsData}) {
    const { name_operator, telephon, email, cif, nif, operatorName, operatorType, qualification, user, comments } = operatorsData; 
    const {handleShowFlag} = useContext(rootContext);
    // funcion para mostrar el select primero de los tipos de operadores
    return (
        <div className="operator-card">
            <header className="operator-card-header">
                <h2>{capitalizeFirstLetter(name_operator)}</h2>
                <p>{capitalizeFirstLetter(operatorName.name)} - {capitalizeFirstLetter(operatorType.name_type)}</p>
            </header>
            <div className="operator-card-content">
                <p><strong>Teléfono:</strong>  <a className="telephon" href={`tel:${telephon}`}>{telephon || 'N/A'}</a></p>
                <p><strong>Email:</strong> {email || 'N/A'}</p>
                <p><strong>CIF:</strong> {cif || 'N/A'}</p>
                <p><strong>NIF:</strong> {nif || 'N/A'}</p>
                <p><strong>Calificación:</strong><RatingComponent qualification={qualification} /></p>
                <p><strong>Creador:</strong> {capitalizeFirstLetter(user.name)} {capitalizeFirstLetter(user.surname)}</p>
                {comments && (
                    <>
                        <div className="comment-box">
                            <p className='comment'><strong>Comentarios</strong></p>
                            {comments}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default OperatorsCardComponent;