import React from 'react'; 
import HorizontalMenuComponent from '../../../generalComponents/horizontalMenu/horizontalMenuComponent';
import './services-style.css';
import {
  Routes,
  Route
} from "react-router-dom";
import CreateServiceComponent from './createService/createServiceComponent';
import AllServicesComponent from './allServices/allServicesComponent';

function ServicesComponent() {
  return (
    <>
        <HorizontalMenuComponent menuSelected={'servicesMenu'} />
        <Routes>
          <Route path='all-services' element={<AllServicesComponent/>}/>
          <Route path='create-service' element={<CreateServiceComponent/>}/>
        </Routes>
    </>
  );
}

export default ServicesComponent;