const ErrorsFactory = {
    login:{
        'You must activate you account':'Tu cuenta no está activa, activala para poder iniciar sesión',
        'User Bloked':'Tu usuario ha sido blqueado por seguridad',
        'Incorrect Credentials':'Tus credenciales son incorrectas'
    },
    admin:{
        'you are admin':'you are admin'
    },
    register:{
        'You can not register':'No puedes usar estas credenciales'
    }
}

export default ErrorsFactory;