import React,{useState,createContext} from 'react'; 
import ServicesComponent from './services/servicesComponent';
import UserAccountComponent from './userAccount/userAccountComponent';
import AdminpanelComponent from './adminCompnent/adminCompnent';
import LoaderComponent from '../../generalComponents/loader/loaderComponent';
import FooterComponent from '../footer/footerComponent';

import {
  Routes,
  Route
} from "react-router-dom";
import CloseSesionComponent from './closeSesion/closeSesionComponent';
import './content-style.css'
export const ContenContex = createContext();

function ContentCompnent() {
  const [showLoader,setShowLoader] = useState(false);
  return (
    <>
       <div className='content-component'>
          <ContenContex.Provider value={{setShowLoader}}>
            {
              showLoader ? <div><LoaderComponent></LoaderComponent></div> :
              <Routes>
                <Route path='services/*' element={<ServicesComponent/>}/>
                <Route path='my-account' element={<UserAccountComponent/>}/>
                <Route path='admin-panel/*' element={<AdminpanelComponent/>}/>
                <Route path='cerrar-sesion' element={<CloseSesionComponent/>}/>
              </Routes>
            }
          </ContenContex.Provider>
      </div>
    <FooterComponent/>
    </>
  );
}

export default ContentCompnent;
