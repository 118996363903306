import React,{useEffect,useState,useRef,useContext} from 'react'; 
import AllUsersService from '../../../../../services/UsersService';
import ButtonsComponent from '../../../../generalComponents/buttons/buttonsComponent';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import './all-users-style.css';
import DataUserComponent from './dataUserComponent';
import { ContenContex } from '../../contentComponent';

function AllUsersComponent() {
    const [usersData,setUserData] = useState([]);
    const sectionDataRef = useRef([]);
    const {setShowLoader} = useContext(ContenContex);

    useEffect(() => {
        setShowLoader(true)
        getAllUsers();
        setShowLoader(false)
    }, []);

    const getAllUsers = async ()=>{
        const allUsersData = await AllUsersService.getAllUsers();
        setUserData(allUsersData.data);
    }
    const seeUserData = (index)=>{
        sectionDataRef.current[index].style.display='block'
    }

    const closeUserdata = (index)=>{
        sectionDataRef.current[index].classList.add("fade-out");
        setTimeout(()=>{
            sectionDataRef.current[index].style.display='none'
            sectionDataRef.current[index].classList.remove("fade-out");
        },300)
    }

    return (
        <section className='all-users-section content-data'>
            {
                usersData.map((user,index)=>{
                    return <div key={index} className='each-user-div'>
                                <span className='each-user-name'>{user.name} {user.surname}</span>
                                <ButtonsComponent 
                                    type='button' 
                                    classButton='info-button'
                                    dispatchClick={()=>seeUserData(index)}
                                    nameButton='Ver informacion'
                                    aling='right'
                                >
                                </ButtonsComponent>
                                <section ref={el => sectionDataRef.current[index] = el} className='user-section-data'>
                                    <div className='div-close'>
                                        <FontAwesomeIcon className='close-user-data' icon={faCircleXmark} onClick={()=>{closeUserdata(index)}}/>
                                    </div>
                                    <DataUserComponent dataUser={user}></DataUserComponent>
                                </section>
                            </div>
                })
            }
        </section>
    );
}

export default AllUsersComponent;