import React,{useState,useEffect} from 'react'; 
import HorizontalMenuComponent from '../../../generalComponents/horizontalMenu/horizontalMenuComponent';
import AllUsersComponent from './allUsers/allUsersComponent';
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import './admin-style.css';
import GuildsComponent from './guilds/guildsComponent';
import WhoIamService from '../../../../services/WhoIam';



function AdminpanelComponent() {
  const [showContent,setShowContent] = useState(false);
  const navigate = useNavigate();
    useEffect(() => {
      getWhoIamAdmin();
    }, []);

  const getWhoIamAdmin = async ()=>{
    const whoIam = await WhoIamService.whoIamAdmin();
    if(!whoIam) return navigate('/services/all-services');
    else setShowContent(true);

    try {
      const whoIam = await WhoIamService.whoIamAdmin();
      if (!whoIam) {
        navigate('/services/all-services');
      } else {
        setShowContent(true);
      }
    }catch (err) {
        navigate('/services/all-services');
    }
  }
  return (
    <>
    {
      !showContent ? '' : 
      <>
        <HorizontalMenuComponent menuSelected={'adminMenu'} />
        <Routes>
            <Route path='all-users' element={<AllUsersComponent/>}/>
            <Route path='gremios' element={<GuildsComponent/>}/>
        </Routes>
      </>
    }
    </>
  );
}

export default AdminpanelComponent;