import React from 'react'; 
import '../login-style.css';
function ForgotPassComponent(props) {

    const changeComponent = () => {
        props.change('show');
    };
    return (
        <form className="form-login rotate-component">
            <h1>Recuperar contraseña</h1>
            <input type="email" placeholder="Email" required/>
            <div className="aside-element">
            </div>
            <div className="aside-element aside-forgot">
                <span onClick={changeComponent}>Iniciar sesión</span>
            </div>
            <button className='login-button primary'>Recuperar</button>
        </form>
    );
}
  
  export default ForgotPassComponent;
  