import React,{useEffect,useState} from 'react'; 
import './button-style.css';

function ButtonsComponent(props) {
    const [addicionalStyle,setAddicionalStyle] = useState({});

    useEffect(() => {
        setAddicionalStyle(props.adinCss)
    }, []);
    const dispatchClickFunction = (ind=false)=>{
        props.dispatchClick(ind);
    }
    const submitButton = ()=>{
        if(props.type==='submit') return <button type={props.type} className={`general-button ${props.classButton} ${props.aling}`} style={addicionalStyle}>{props.nameButton}</button>
        else return <button type={props.type} className={`general-button ${props.classButton} ${props.aling}`} onClick={dispatchClickFunction} style={addicionalStyle}>{props.nameButton}</button>
    }
    return (
        submitButton()
    );
}

export default ButtonsComponent;
