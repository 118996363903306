import React, { useEffect,useState,createContext } from 'react'; 
import MenuComponent from '../menu/menuComponent';
import MenuResponsiveComponent from '../menu-responsive/menuResponsiveComponent';
import ContentComponent from '../content/contentComponent';
import {guards} from '../../../guards/guards'
import Auth from '../../../helpers/auth';
import {
  useNavigate
} from "react-router-dom";
export const DataContext = createContext();
function MainComponent() {
  const navigate = useNavigate();
  const [showContent,setShowComponent] = useState(false);
  document.addEventListener('sessionEnded', (event) => {
    Auth.destroySesion();
    return navigate('/login');
  });
  
  const mountComponent = async () => {
    const checkSesion = await guards.guardsPanel();
    if(checkSesion==='login') return navigate('/login')
    setShowComponent(true)
  }
  
  useEffect(()=>{
    mountComponent();
  },[])
  return (
    <>
      {
        showContent ?  
          <div className='main-content'>
            <MenuComponent/>
            <MenuResponsiveComponent/>
            <ContentComponent/>
          </div> : ''
      }
    </>
  );
}

export default MainComponent;