import React from 'react'; 
import './footer-style.css';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Redirect,
//   Route
// } from "react-router-dom";
// import './App.css';

function FooterComponent() {
  return (
        <div className='footer'>
            <p>
                ©2024 Software desarrollado por <strong>Jesus Lopez Rodriguez</strong> y gestionado por <strong>Isaac Beteta</strong>.
            </p>
        </div>
  );
}

export default FooterComponent;