import ApiService from "../helpers/api";

const getUserData = (id) => {
    return ApiService.get(`users/get_user/${id}`);
}
const updateData = (data) =>{
    return ApiService.update(`users/update_user/${data.id}`,data);
}

const changePassword = (data) =>{
    return ApiService.update(`users/change_password`,data);
}

const UserService = {
    getUserData,
    updateData,
    changePassword
}

export default UserService;