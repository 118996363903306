import React,{useRef,useEffect,useState} from 'react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRightFromBracket,faUserCircle,faHouseCircleCheck,faScrewdriverWrench,faBars,faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import './menu-responsive-style.css';
import { Link } from 'react-router-dom';
import WhoIamService from '../../../services/WhoIam';
import ErrorsFactory from '../../../helpers/errorsFactory';


function MenuResponsiveComponent() {
    const itemsRef = useRef([]);
    const ulResponsiveRef = useRef();
    const [checkIfIamAdmin,setCheckIfIamAdmin] = useState(false);
    useEffect(() => {
        getWhoIamAdmin();
    }, []);
    const menu = [
        {
            name:'Servicios',
            icon:faHouseCircleCheck,
            link:'services',
            show:true,
        },
        {
            name:'Mi Cuenta',
            icon:faUserCircle,
            link:'my-account',
            show:true,
        },
        {
            name:'Administracion',
            icon:faScrewdriverWrench,
            link:'admin-panel',
            show:checkIfIamAdmin
        },
        {
            name:'Cerrar Sesión',
            icon:faRightFromBracket,
            link:'cerrar-sesion',
            show:true
        }
    ]
    const addClass = (key)=>{
        itemsRef.current.map((item,i)=>{
            if(i===key) return item.classList.add('active-responsive');
            else return item.classList.remove('active-responsive');
        })
        return;
    }

    const showHiddenMenuResposive = ()=>{
        if(ulResponsiveRef.current.style.display==='block') return ulResponsiveRef.current.style.display='none';
        return ulResponsiveRef.current.style.display='block'
    }

    const getWhoIamAdmin = async ()=>{
        const whoIam = await WhoIamService.whoIamAdmin();
        if(ErrorsFactory.admin[whoIam]) setCheckIfIamAdmin(true);
    }
    return (
        <nav className="menu-responsive">
            <FontAwesomeIcon className='icon-bars-menu' icon={faBars} onClick={()=> showHiddenMenuResposive()}/>
            <ul id='ulMenuRes' ref={ulResponsiveRef}>
                <div>
                    <FontAwesomeIcon className='icon-close-menu' icon={faCircleXmark} onClick={()=> showHiddenMenuResposive()}/>
                </div>
                {
                    menu.map((item,i) =>{
                        if(item.show){
                            return  <Link key={i} to={item.link}>
                                        <li className={`dropdown_item-${i}`} ref={el =>{
                                            if(el!==null){
                                                itemsRef.current[i] = el
                                                if(item.link===window.location.pathname.split('/')[1]) itemsRef.current[i].classList.add('active-responsive');
                                            }
                                        }} onClick={()=> addClass(i)}>
                                            <FontAwesomeIcon className='icon-menu-responsive' icon={item.icon}/>
                                            <span className="tooltip">{item.name}</span>
                                        </li>
                                    </Link>
                        }
                    })
                }
            </ul>
        </nav>
    );
}

export default MenuResponsiveComponent;