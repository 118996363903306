import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import React from 'react'; 
import ModalsChildsFactory from './factorytModalsChilds';
import './parent-moda-style.css';
function ParentModalComponent({modalSelected,showModal}) {
  const modalChaildSelected = ModalsChildsFactory[modalSelected].view();
  const closeModal = () => {
    showModal(false)
  };
  return (
      <div className='parent-modal'>
        <div className='child-modal'>
          <div className='close-modal' onClick={closeModal}>
            <FontAwesomeIcon className='icon-close' icon={faCircleXmark}/>
          </div>
            {modalChaildSelected}
        </div>
      </div> 
  );
}

export default ParentModalComponent;
