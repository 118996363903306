import Auth from '../helpers/auth';
import loginService from '../services/LoginService'
function guardsPanel(){
    return loginService.checkUser().then(data=>{
        if(!data){
            Auth.destroySesion();
            return 'login';
        }
        if(window.location.pathname==='/login') return 'panel';
        return 'panel';
    }).catch(err=>{
        Auth.destroySesion();
        return 'login';
    })
};

export const guards = {
    guardsPanel
}