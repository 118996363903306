import ApiService from "../helpers/api";

const emailForactiveUser = (data) => {
    return ApiService.post(`resend_email`,data);
}

const ResendEmailService = {
    emailForactiveUser
}

export default ResendEmailService;