import axios from 'axios';
import Auth from "./auth";
import routesAvoid from "./avoidRoutescloseSession";

const REACT_APP_URL = process.env.REACT_APP_URL;

// Crear una instancia de Axios con configuración predeterminada
const axiosInstance = axios.create({
  baseURL: REACT_APP_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

const getbyTokenBearer = async (url, token) => {
  try {
    const response = await axiosInstance.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data
  } catch (error) {
      return checkIfSessionExpired(error,url);
  }
}

const get = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    return response.data
  } catch (error) {
      return checkIfSessionExpired(error,url);
  }
}

const post = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response.data
  } catch (error) {
      return checkIfSessionExpired(error,url);
  }
}

const update = async (url, data) => {
  try {
    const response = await axiosInstance.put(url, data);
    return response.data
  } catch (error) {
      return checkIfSessionExpired(error);
  }
}

const deleteData = async (url) => {
  try {
    const response = await axiosInstance.delete(url);
    return response.data
  } catch (error) {
      return checkIfSessionExpired(error,url);
  }
}

const formDataMethod = async (url, data, method) => {
  try {
    const response = await axiosInstance({
      url: url,
      method: method,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data
  } catch (error) {
      return checkIfSessionExpired(error,url);
  }
}

const checkIfSessionExpired = (error,url) => {
  let testRoute = routesAvoid.some(element => element !== url);
  if(!testRoute) return false;
  if (error.response.status === 401) {
    if (Auth.getUserId()) {
      const sessionEndedEvent = new CustomEvent('sessionEnded');
      document.dispatchEvent(sessionEndedEvent);
    }
    return false;
  }
  return error.response.data;

}

const ApiService = {
  get,
  post,
  update,
  deleteData,
  formDataMethod,
  getbyTokenBearer,
};

export default ApiService;
