import React,{useEffect} from 'react';
import {
    useNavigate
} from "react-router-dom";
import Auth from '../../../../helpers/auth';
import LoginService from '../../../../services/LoginService';
function CloseSesionComponent() {
    const navigate = useNavigate();
    useEffect(() => {
        closeSesion();
    }, []);
    const closeSesion = async ()=>{
        await LoginService.logout();
        Auth.destroySesion();
        navigate("/login");
    }
    return (
        <div></div>
    );
}

export default CloseSesionComponent;