import React, { useContext, useEffect,useRef } from 'react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEye,faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import LoginService from '../../../../../services/LoginService';
import { DataContext } from '../../../../panelControl/main/mainComponent';
import Auth from '../../../../../helpers/auth';

function SessionExpiredModalComponent(props) {
  useEffect(() => {
  }, [props]);
  const inputPassRef = useRef(null);
  const inputEmailRef = useRef(null);
  const showHiddenIconOpenEye = useRef(null);
  const showHiddenIconCloseEye = useRef(null);
  const {closeExpiredSesionModal} = useContext(DataContext);
  const changeType = (key) => {
      if(inputPassRef.current.type==='password'){
          inputPassRef.current.type='text'
          showHiddenIconCloseEye.current.classList.remove('hidde-icon')
          showHiddenIconOpenEye.current.classList.add('hidde-icon')
          return;
      }
      inputPassRef.current.type='password';
      showHiddenIconCloseEye.current.classList.add('hidde-icon')
      showHiddenIconOpenEye.current.classList.remove('hidde-icon')
      return;
  };

  const handleSubmit = async (e)=>{
    e.preventDefault();
    const login = await LoginService.login({email:inputEmailRef.current.value,password:inputPassRef.current.value});
    Auth.newSesion(login.data)
    closeExpiredSesionModal();
  }
  return (
    <div className='child-in'>
        <h1 className='title-modal'>Tu Session ha expirado</h1>
        <form className="form-login-expired" onSubmit={handleSubmit}>
            <input type="email" ref={inputEmailRef} placeholder="Email" required/>
            <span 
                className='span-icon hidde-icon'
                ref={showHiddenIconCloseEye}
            >
              <FontAwesomeIcon 
                  className='icon-form' 
                  onClick={()=>changeType(0)} 
                  icon={faEyeSlash}
              />
            </span>
            <span className='span-icon' ref={showHiddenIconOpenEye}>
              <FontAwesomeIcon 
                  className='icon-form' 
                  onClick={()=>changeType(0)} 
                  icon={faEye}
              />
            </span>
            <input ref={inputPassRef} type="password" placeholder="Password" required/>
            <button className='login-button primary'>Iniciar Sesión</button>
        </form>
    </div>
  );
}

export default SessionExpiredModalComponent;
