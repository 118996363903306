import React,{useEffect,useState,useRef} from 'react'; 
import GuildsService from '../../../../../services/GuildsService';
import GuildsTypesService from '../../../../../services/GuildsTypesServices';
import ButtonsComponent from '../../../../generalComponents/buttons/buttonsComponent';
import './guilds-style.css';

function OperatorsTypeComponent({dataOperator,getAllGuilds,handleShowFlag}) {
    const [operatorData,setOperatorData] = useState({id:'',name:'',need_calification:'',_id_operator_type:[]});
    const [operatorType,setOperatoType] = useState({name_type:''});
    useEffect(() => {
        setOperatorData(dataOperator)
    }, []);

    const handleChange = (e) =>{
        const {name,value} = e.target;
        if(name==='name_type') return setOperatoType({...operatorType,[name]:value});
        return setOperatorData({...operatorData,[name]:value});
    }

    const updateData = async (e) =>{
        e.preventDefault()
        let updateGuild = await GuildsService.updateOperatorName(operatorData);
        if(updateGuild==='error') handleShowFlag('error','Ha Habido Un Error','topToDown');
        else 
            handleShowFlag('success','Datos Actualizados Corractamente','topToDown');
            getAllGuilds();
    }

    const addNewtype = async (e)=>{
        e.preventDefault()
        let newType = await GuildsTypesService.createOperatorType(operatorData.id,operatorType);
        if(newType==='error') handleShowFlag('error','Ha Habido Un Error','topToDown');
        else 
            handleShowFlag('success','Datos Creados Corractamente','topToDown');
            setOperatoType({name_type:''});
            getAllGuilds();
    }

    const deleteType = async (id) =>{
        let deleteType = await GuildsTypesService.deleteType(id);
        if(deleteType==='error') handleShowFlag('error','Ha Habido Un Error','topToDown');
        else 
            handleShowFlag('success','Datos Borrados Corractamente','topToDown');
            getAllGuilds();
    }

    return (
        <>
            <form onSubmit={updateData}>         
                <input type="hidden" name="id" placeholder="id" value={operatorData.id} onChange={handleChange}/>
                <label>Nombre Operador</label>
                <input type="text" name="name" placeholder="Nombre" value={operatorData.name} onChange={handleChange}/>
                <label>Necesita calificacion</label>
                <select type="text" name="need_calification" placeholder="Necesita calificacion" value={operatorData.need_calification} onChange={handleChange}>
                    <option value='1'>Si</option>
                    <option value='0'>No</option>
                </select>
                <ButtonsComponent 
                    type='submit' 
                    classButton='info-button'
                    nameButton='Actualizar'
                    aling='right'
                ></ButtonsComponent>
            </form>
            <br/><hr/>
            {
                dataOperator.operatorTypes.length>0 ? <><h2 className='second-title'>Tipos de Gremio</h2>
                <div className='operators-type'>
                    {
                        dataOperator.operatorTypes.map((type,index)=>{
                            return <div key={index} className='each-type'>
                                {type.name_type}
                                <ButtonsComponent 
                                    type='button' 
                                    classButton='delete-button'
                                    dispatchClick={()=>deleteType(type.id)}
                                    nameButton='Borrar tipo'
                                    aling='right'
                                    adinCss={{'marginLeft':'10px'}}
                                ></ButtonsComponent>
                            </div>
                        })
                    }
                </div>
                <br/><hr/></> : ''
            }
            <form className='insert-type-form' onSubmit={addNewtype}>
                <label>Insertar tipo de operador</label>
                <input type="text" name="name_type" placeholder="Inserta un tipo" value={operatorType.name_type} onChange={handleChange}/>
                <ButtonsComponent 
                    type='submit' 
                    classButton='info-button'
                    nameButton='Insertar tipo'
                    aling='right'
                ></ButtonsComponent>
            </form>
        </>
    );
}

export default OperatorsTypeComponent;