import React,{useRef,useEffect,useState} from 'react'; 
import './user-acccount-style.css';
import UserService from '../../../../services/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
function ChangePasswordComponent(props) {
    const {handleShowFlag,hiddeChangePassword} = props;
    const changePasswordRef = useRef([]);
    const initValues = {password:"",new_password:"",confirmation:""};
    const [isSubmit,setIsSubmit] = useState(false);
    const [formErrors,setFormErrors] = useState({});
    const [passWordValues,setPassWordValues] = useState(initValues);
    
    const handleChange = (e) =>{
        const {name,value} = e.target;
        return setPassWordValues({...passWordValues,[name]:value});
    }
    const handleSubmit = async () =>{
        setFormErrors(validate(passWordValues));
        setIsSubmit(true);
    }

    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmit){
            updatePassword(passWordValues);
        }
    },[formErrors]);

    const updatePassword = async (passWordValues)=>{
        
        const changePass = await UserService.changePassword(passWordValues);

        if(changePass==='Not Allowed') return handleShowFlag('error','Ha Habido Un Problema En La Actualizacion De la Password','topToDown');
        
        return handleShowFlag('success','Datos Actualizados Correctamente','topToDown');
    
    }

    const validate = (values) => {
        const errors = {};
        
        if(!values.password || !values.new_password) errors.password='La password es obligatoria';
        
        if(values.new_password.search(/[a-z]/)<0) errors.password='La password debe tener al menos una minúscula';
        
        if(values.new_password.search(/[A-Z]/)<0) errors.password='La password debe tener al menos una mayúscula';
        
        if(values.new_password.search(/[0-9]/)<0) errors.password='La password debe tener al menos un número';
        
        if(values.new_password.length<8) errors.password='La password debe tener al menos 8 carácteres';
        
        if(values.new_password!==values.confirmation) errors.password='Las contraseñas no coinciden';
        
        return errors;

    }
  return (
    <div ref={changePasswordRef} className='content-data change-password-div'>
        
        <div className='tabs-parent'>
            <FontAwesomeIcon className='icon-tab double-tab' icon={faXmark} title='Cerrar ventana' onClick={()=>hiddeChangePassword(changePasswordRef)} />
            <FontAwesomeIcon className='icon-tab double-tab' icon={faSquareCheck} title='guardar' onClick={handleSubmit}/>
        </div>
        <h1>Cambiar mi contraseña</h1>
        <form>
            <input className='read-only' placeholder='Contraseña actual' name='password' type="password" value={passWordValues.password} onChange={handleChange}/>
            <input className='read-only' placeholder='Nueva Contraseña' name='new_password' type="password" value={passWordValues.new_password} onChange={handleChange}/>
            <input className='read-only' placeholder='Confirmar Contraseña' name='confirmation' type="password" value={passWordValues.confirmation} onChange={handleChange}/>
            <p className='paragraph-error'>{formErrors.password}</p>
        </form>

    </div>
  );
}

export default ChangePasswordComponent;

