import React,{useState} from 'react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPencil,faSquareCheck} from '@fortawesome/free-solid-svg-icons';

import './tabs.css';

function TabsComponent(props) {
    const [showPencilOrButton,setShowPencilOrButton] = useState(true);

    const changeData = ()=>{
        props.changeDataFunction()
        setShowPencilOrButton(false)
    }
    const sendDataChanged = ()=>{
        props.dataChangedFunction()
        setShowPencilOrButton(true)
    }
    return (
        <div className='tabs-parent'>
            {
                showPencilOrButton ? 
                <FontAwesomeIcon className='icon-tab' icon={faPencil} title='actualizar' onClick={changeData}/>
                : <FontAwesomeIcon className='icon-tab' icon={faSquareCheck} title='guardar' onClick={sendDataChanged}/>
            }
        </div>
    );
}

export default TabsComponent;
