import React, { useRef,useEffect,useState,useContext } from 'react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEye,faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import { Link,useNavigate } from 'react-router-dom';
import LoginService from '../../../services/LoginService';
import '../login-style.css';
import ErrorsFactory from '../../../helpers/errorsFactory';
import ParentModalComponent from '../../generalComponents/modals/parentModalComponent';
import Auth from '../../../helpers/auth';
import { rootContext } from '../../../App';


function FormLoginComponent(props) {
    useEffect(() => {
        if(props.addFlip) addClassFlip();
    }, [props]);
    const inputPassRef = useRef(null);
    const inputEmailRef = useRef(null);
    const formRef = useRef(null);
    const showHiddenIconOpenEye = useRef(null);
    const showHiddenIconCloseEye = useRef(null);
    const [showSendEmail,setShowSendEmail] = useState(false);
    const navigate = useNavigate();
    const {handleShowFlag,setShowModalSelected,setModalSelected} = useContext(rootContext);
    const addClassFlip = () =>{
        formRef.current.classList.add("rotate-component");
    }
    const changeType = (key) => {
        if(inputPassRef.current.type==='password'){
            inputPassRef.current.type='text'
            showHiddenIconCloseEye.current.classList.remove('hidde-icon')
            showHiddenIconOpenEye.current.classList.add('hidde-icon')
            return;
        }
        inputPassRef.current.type='password';
        showHiddenIconCloseEye.current.classList.add('hidde-icon')
        showHiddenIconOpenEye.current.classList.remove('hidde-icon')
        return;
    };

    const changeComponent = ()=>{
        props.change('showForgot');
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        const login = await LoginService.login({email:inputEmailRef.current.value,password:inputPassRef.current.value});
        if(ErrorsFactory.login[login]){
            handleShowFlag('error',ErrorsFactory.login[login]);
            if(ErrorsFactory.login[login]===ErrorsFactory.login['You must activate you account']) setShowSendEmail(true);
            return;
        }
        handleShowFlag('success','Acceso correcto');
        Auth.newSesion(login.data)
        return navigate("/services");
    }

    const showModal = ()=>{
        setShowModalSelected(true);
        setModalSelected('modalSendEmailActiveAcount');
        return;
    }


    return (
        <div>
            <form ref={formRef} className="form-login" onSubmit={handleSubmit}>
                <h1>Inicio de sesión</h1>
                <input ref={inputEmailRef} type="email" placeholder="Email" required/>
                <span 
                    className='span-icon hidde-icon'
                    ref={showHiddenIconCloseEye}
                >
                    <FontAwesomeIcon 
                        className='icon-form' 
                        onClick={()=>changeType(0)} 
                        icon={faEyeSlash}
                    />
                </span>
                <span className='span-icon' ref={showHiddenIconOpenEye}>
                    <FontAwesomeIcon 
                        className='icon-form' 
                        onClick={()=>changeType(0)} 
                        icon={faEye}
                    />
                </span>
                <input ref={inputPassRef} type="password" placeholder="Password" required/>
                <div className="aside-element">
                    <Link className='span-register' to='/register-acount'>
                        <span>Registrarme</span>
                    </Link>
                </div>
                <div className="aside-element">
                    <span onClick={changeComponent}>¿Has olvidado la contraseña?</span>
                </div>
                <button className='login-button primary'>Iniciar Sesión</button>
                <div className='error-message'>
                    {
                        showSendEmail ? <span className="send-email" onClick={showModal}>Enviar de nuevo Email</span> : ''
                    }
                </div>
            </form>
        </div>
    );
}
  
  export default FormLoginComponent;