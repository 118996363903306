import React from 'react'; 
import './loader-principal-style.css';

function LoaderPrinciaplComponent() {
  return (
    <div className="section-loading">
      <h1>ME&YOU</h1>
      <ul className="list-bars">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
}

export default LoaderPrinciaplComponent;