const jflaFactoryValues = {
    success:{
        values:(text,animation)=>{
            return jflaFactoryValues.objectReturnt(text,true,'success',animation,{'backgroundColor':'#9ffcbb','color':'#000','border':'1px solid #000'});
        }
    },
    info:{
        values:(text,animation)=>{
            return jflaFactoryValues.objectReturnt(text,true,'info',animation,{'backgroundColor':'#445fb8','color':'#fff','border':'1px solid #fff'});
        }
    },
    warning:{
        values:(text,animation)=>{
            return jflaFactoryValues.objectReturnt(text,true,'warning',animation,{'backgroundColor':'#fcd217','color':'#000','border':'1px solid #000'});
        }
    },
    error:{
        values:(text,animation)=>{
            return jflaFactoryValues.objectReturnt(text,true,'error',animation,{'backgroundColor':'#f598a6','color':'#fff','border':'1px solid #fff'});
        }
    },
    objectReturnt: function(text,sound,typeF,animation,customStyles){
        return {text,sound,typeF,animation,customStyles}
    }
}

export default jflaFactoryValues;