import ApiService from "../helpers/api";

const getAllOperatorsName = () => {
    return ApiService.get(`operators_name/`);
}
const createOperatorName = (data) => {
    return ApiService.post(`operators_name/create`,data);
}

const updateOperatorName = (data) => {
    return ApiService.update(`operators_name/update/${data.id}`,data);
}

const deleteOperaTorName = (id) => {
    return ApiService.deleteData(`operators_name/delete/${id}`);
}

const GuildsService = {
    getAllOperatorsName,
    createOperatorName,
    deleteOperaTorName,
    updateOperatorName
}

export default GuildsService;