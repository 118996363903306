import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown, faMeh, faSmile, faGrin, faStar, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const getRating = (value) => {
  if (value >= 0 && value < 5) {
    return { text: 'Pésima', icon: faFrown, color: '#e86258' };
  } else if (value >= 5 && value < 10) {
    return { text: 'Malo', icon: faMeh, color: '#e8a451' };
  } else if (value >= 10 && value < 15) {
    return { text: 'Normal', icon: faSmile, color: '#f0ca62' };
  } else if (value >= 15 && value < 20) {
    return { text: 'Bueno', icon: faGrin, color: '#b8f595' };
  } else if (value >= 20 && value < 23) {
    return { text: 'Muy Bueno', icon: faStar, color: '#9ceb6e' };
  } else if (value >= 23 && value <= 25) {
    return { text: 'Excelente', icon: faThumbsUp, color: '#3ea105' };
  }
  return { text: 'N/A', icon: faMeh, color: 'gray' };
};

const RatingComponent = ({ qualification }) => {
  const { text, icon, color } = getRating(qualification);

  return (
    <>
    <FontAwesomeIcon icon={icon} style={{ color, marginRight: '8px', marginLeft: '8px' }} />
    <span>{text}</span>
    </>
  );
};

export default RatingComponent;
