import React,{useEffect,useState,useContext} from 'react'; 
import './user-acccount-style.css';
import UserService from '../../../../services/UserService';
import Auth from '../../../../helpers/auth';
import TabsComponent from '../../../generalComponents/tabs/tabsComponent';
import ChangePasswordComponent from './changePassWord';
import { rootContext } from '../../../../App';
import { ContenContex } from '../contentComponent';

function UserAccountComponent() {
  const initValues = {name:"",surname:"",email:"",date_of_birth:""};
  const [showChangePassword,setShowChangePassword]= useState(false);
  const [userData,setUserData] = useState(initValues);
  const [readOnlyState,setReadOnlyState] = useState(true);
  const {handleShowFlag} = useContext(rootContext);
  const {setShowLoader} = useContext(ContenContex);

  useEffect(() => {
      setShowLoader(true)
      getUserData();
      setShowLoader(false)
  }, []);
  const getUserData = async ()=>{
    const idUser = Auth.getUserId();
    const userData = await UserService.getUserData(idUser);
    setUserData(userData.data);
  }
  const changeData = () =>{
    return setReadOnlyState(false)
  }
  const handleChange = (e) =>{
    const {name,value} = e.target;
    return setUserData({...userData,[name]:value});
  }
  const handleSubmit = async () =>{

    const updateUser = await UserService.updateData(userData);
    
    if(updateUser.data==='success') return handleShowFlag('success','Datos Actualizados Correctamente','topToDown');
    
    return handleShowFlag('error','Ha Habido Un Problema En La Actualizacion','topToDown');
  
  }

  const hiddeChangePassword = (ref) =>{
    ref.current.classList.add('shrink')
    return setTimeout(()=>{
      setShowChangePassword(false)
      ref.current.classList.remove('shrink')
    },500)
  }

  return (
    <>
      <div className='content-data'>

        <TabsComponent changeDataFunction={changeData} dataChangedFunction={handleSubmit}/>

        <h1>Mi perfil <span className='change-passsword-button' onClick={()=>setShowChangePassword(true)}>Cambiar contraseña</span></h1>
        
        <form>
            <input className='read-only' name='name' type="text" value={userData.name} onChange={handleChange} readOnly={readOnlyState}/>
            <input className='read-only' name='surname' type="text" value={userData.surname} onChange={handleChange} readOnly={readOnlyState}/>
            <input className='read-only' name='email' type="email" value={userData.email} onChange={handleChange} readOnly={readOnlyState}/>
            <input className='read-only' name='date_of_birth' type="date" value={userData.date_of_birth} onChange={handleChange} readOnly={readOnlyState}/>
        </form>

      </div>
      {
        showChangePassword ? <ChangePasswordComponent handleShowFlag={handleShowFlag} hiddeChangePassword={hiddeChangePassword}/> : ''
      }
    </>
  );
}

export default UserAccountComponent;