import React,{useState,useRef, useEffect} from 'react'; 
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEye,faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import './register-style.css';
import LoginService from '../../services/LoginService';
import ErrorsFactory from '../../helpers/errorsFactory';
function RegisterComponent() {
    const initalValues = { name:"",surname:"",email:"",password:"",confirmation:"",terms_accepted:0,date_of_birth:""}
    const [formValues,setFormValues] = useState(initalValues);
    const [formErrors,setFormErrors] = useState({});
    const [isSubmit,setIsSubmit] = useState(false);
    const [success,setSuccess] = useState(null);
    const [error,setError] = useState(null);
    const inputPassRef = useRef([]);
    const showHiddenIconOpenEye = useRef([]);
    const showHiddenIconCloseEye = useRef([]);
    const checkboxTermns = useRef([]);
    const changeType = (key) => {
        if(inputPassRef.current[key].type==='password'){
            inputPassRef.current[key].type='text'
            showHiddenIconCloseEye.current[key].classList.remove('hidde-icon')
            showHiddenIconOpenEye.current[key].classList.add('hidde-icon')
            return;
        }
        inputPassRef.current[key].type='password';
        showHiddenIconCloseEye.current[key].classList.add('hidde-icon')
        showHiddenIconOpenEye.current[key].classList.remove('hidde-icon')
        return;
    };
    const handleChange = (e) =>{
        const {name,value} = e.target;
        if(name==='terms_accepted'){
            let valChecked=0;
            if(e.target.checked) valChecked=1
            return setValues(name,valChecked);
        }
        return setValues(name,value);
    }

    const setValues = (name,value)=>{
        setFormValues({...formValues,[name]:value})
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
    }

    useEffect(()=>{
        if(Object.keys(formErrors).length === 0 && isSubmit){
            setSuccess(null)
            setError(null)
            registerUser(formValues);
        }
    },[formErrors]);

    const registerUser = async (formValues)=>{
        const register = await LoginService.register(formValues);
        if(ErrorsFactory.register[register]) return setError(ErrorsFactory.register[register]);
        checkboxTermns.current.click();
        setFormValues(initalValues);
        return setSuccess('Su cuenta ha sido creada con éxito, hemos enviado un email de confirmaciónp ara que active su cuenta');
    } 
    const validate = (values) => {
        const errors = {};
        let patt=new RegExp(/^(([^<>()[\].,;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i);
        if(!values.name)errors.name='El nombre es obligatorio';
        if(!values.surname) errors.surname='El apellido es obligatorio';
        if(!values.password) errors.password='La password es obligatoria';
        if(values.password.search(/[a-z]/)<0) errors.password='La password debe tener al menos una minúscula';
        if(values.password.search(/[A-Z]/)<0) errors.password='La password debe tener al menos una mayúscula';
        if(values.password.search(/[0-9]/)<0) errors.password='La password debe tener al menos un número';
        if(values.password.length<8) errors.password='La password debe tener al menos 8 carácteres';
        if(values.password!==values.confirmation) errors.password='Las contraseñas no coinciden';
        if(!patt.test(values.email)) errors.email='Debes de insertar un email correcto';
        if(!values.email) errors.email='El email es obligatorio';
        if(!values.date_of_birth) errors.date_of_birth='La fecha es obligatoria';
        if(values.terms_accepted==0) errors.terms_accepted='Debes de aceptar los términos';
        return errors;
    }

    return (
        <div className="parent-register">
            <div className='parent-form'>
                <form className='form-register' onSubmit={handleSubmit}>
                    <h1>Registrarse en ME&YOU</h1>
                    <input  name="name" value={formValues.name} type="text" onChange={handleChange} placeholder="Nombre"/>
                    <p className='paragraph-error'>{formErrors.name}</p>
                    <input  name="surname" value={formValues.surname} type="text" onChange={handleChange} placeholder="Apellidos"/>
                    <p className='paragraph-error'>{formErrors.surname}</p>
                    <input  name="email" value={formValues.email} type="email" onChange={handleChange} placeholder="Email"/>
                    <p className='paragraph-error'>{formErrors.email}</p>
                    <span 
                        className='span-icon hidde-icon'
                        ref={el =>showHiddenIconCloseEye.current[0] = el}
                    >
                        <FontAwesomeIcon 
                            className='icon-form' 
                            onClick={()=>changeType(0)} 
                            icon={faEyeSlash}
                        />
                    </span>
                    <span className='span-icon' ref={el =>showHiddenIconOpenEye.current[0] = el}>
                        <FontAwesomeIcon 
                            className='icon-form' 
                            onClick={()=>changeType(0)} 
                            icon={faEye}
                        />
                    </span>
                    <input  
                        ref={el =>inputPassRef.current[0] = el}
                        value={formValues.password} 
                        type="password" 
                        name="password"
                        onChange={handleChange}
                        placeholder="Password"
                    />
                    <p className='paragraph-error'>{formErrors.password}</p>
                    <span className='span-icon hidde-icon' ref={el =>showHiddenIconCloseEye.current[1] = el}>
                        <FontAwesomeIcon 
                            className='icon-form' 
                            onClick={()=>changeType(1)} 
                            icon={faEyeSlash}
                        />
                    </span>
                    <span className='span-icon' ref={el =>showHiddenIconOpenEye.current[1] = el}>
                        <FontAwesomeIcon 
                            className='icon-form' 
                            icon={faEye} 
                            onClick={()=>changeType(1)}
                        />
                    </span>
                    <input  
                        ref={el =>inputPassRef.current[1] = el}
                        value={formValues.confirmation} 
                        type="password" 
                        name="confirmation"
                        onChange={handleChange} 
                        placeholder="Confirma la Password"
                    />
                    <p className='paragraph-error'>{formErrors.password}</p>
                    <input value={formValues.date_of_birth} type="date" name="date_of_birth" onChange={handleChange} placeholder="fecha de nacimiento"/>
                    <p className='paragraph-error'>{formErrors.date_of_birth}</p>
                    <input ref={checkboxTermns} value={formValues.terms_accepted} type="checkbox" name="terms_accepted" onChange={handleChange} className="input-show" />
                    <span className="accept-terms">Acepto los términos</span>
                    <span className='terms'>
                        Autorizo a que <strong>ME&YOU</strong>, como responsable de esta web,
                        pueda almacenar y utilizar los datos personales que he facilitado en este formulario 
                        para crear una cuenta de usuario. 
                        Los datos obtenidos en este formulario no se cederán a terceros salvo en los casos en que exista 
                        una obligación legal o para enviarme instrucciones y datos de acceso.
                        Reconozco saber que tengo derecho de acceso a mis datos personales, 
                        así como a su rectificación, supresión, oposición, 
                        portabilidad y limitación del tratamiento desde <strong>lbmeyou@gmail.com</strong> de conformidad con la Política de Privacidad, 
                        Términos y condiciones y Política de Cookies.
                    </span>
                    <p className='paragraph-error accept-error'>{formErrors.terms_accepted}</p>
                    <button className='login-button primary'>Registrarme</button>
                    <Link className='span-register' to='/login'>
                        <span>Iniciar Sesión</span>
                    </Link>
                    <p className='paragraph-error accept-error'>{error}</p>
                    <p className='successs-message'>{success}</p>
                </form>
            </div>
        </div>
    );
}
  
  export default RegisterComponent;
  