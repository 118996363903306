import ApiService from "../helpers/api";

const createOperatorType = (id,data) => {
    return ApiService.post(`operators_type/create/${id}`,data);
}
const deleteType = (id) =>{
    return ApiService.deleteData(`operators_type/delete/${id}`);
}

const GuildsTypesService = {
    createOperatorType,
    deleteType
}

export default GuildsTypesService;