import React,{useState,useEffect} from 'react'; 
import FormLoginComponent from './formLogin/formLogin';
import ForgotPassComponent from './forgotPass/forgotPasswordComponent';
import {guards} from '../../guards/guards'
import {
    useNavigate
  } from "react-router-dom";
import './login-style.css';
function LoginComponent() {
    const navigate = useNavigate();
    const [showLogin,setShowLogin] = useState('show');
    const [showContent,setShowComponent] = useState(false);
    const [addClassFlip,setShowFlip] = useState(false)
    const changeComponent = (show) => {
        if(show==='show'){
            setShowLogin(show);
            setShowFlip(true);
        }else{
            setShowLogin(show);
        }
    };

    const mountComponent = async () => {
        const checkSesion = await guards.guardsPanel();
        if(checkSesion!=='login') return navigate('/services/all-services')
        setShowComponent(true)
    }
    useEffect(()=>{
        mountComponent();
    },[])
    return (
        <>
            {
                showContent ? 
                <div className="parent">
                    <section className="aside aside-left">
                        <span className="title-span">Bienvenido a ME&YOU</span>
                        {
                            showLogin==='show' ? <FormLoginComponent change={changeComponent} addFlip={addClassFlip} /> 
                            : <ForgotPassComponent change={changeComponent} />
                        }
                    </section>
                    <section className="aside image-login">
                        
                    </section>
                </div> : ''
            }
        </>
    );
}
  
  export default LoginComponent;
  